import * as React from "react";
import "./TeaserPage2023.scss";
import classNames from "classnames";
import Footer from "../../components/Footer";
import { Accolades } from "../media/Accolades";
import { ReactComponent as Logo } from "./img/real_logo.svg";

interface ITeaserPage2023Props {}

export const TeaserHero: React.FunctionComponent = () => {
  return (
    <div className="TeaserHero">
      <div className="TeaserHero__content">
        <Logo className="RealLogo" />
      </div>
    </div>
  );
};

export const FancyHr: React.FunctionComponent = () => {
  return (
    <PadPanel>
      <div className="FancyHr"></div>
    </PadPanel>
  );
};

export const PipeJoined: React.FunctionComponent<{ parts: string[] }> = (
  props
) => {
  return (
    <span className="PipeJoined">
      {props.parts.map((part, i) => (
        <span key={i}>
          {part}
          {i < props.parts.length - 1 ? (
            <span className="PipeJoined__pipe"></span>
          ) : null}
        </span>
      ))}
    </span>
  );
};

export const KeynoteCallout: React.FunctionComponent = () => {
  return (
    <div className="KeynoteCallout">
      <div className="KeynoteCallout__content">
        <h2 className="KeynoteCallout__title">CES 2023 KEYNOTE ADDRESS</h2>
        <h3 className="KeynoteCallout__subtitle">
          <PipeJoined parts={["January 5, 2023", "11am CST (9am PST)"]} />
        </h3>
        <PadPanel>
          <p>
            Join Chairman and CEO John May as he provides a unique look at how
            John Deere technology is helping customers do more with less — and
            feed our growing world.
          </p>
        </PadPanel>
      </div>
    </div>
  );
};

export const DateTimeCallout: React.FunctionComponent = () => {
  return (
    <PadPanel>
      <div className="DateTimeCallout">
        <div className="DateTimeCallout__content">
          <h2 className="DateTimeCallout__title">
            Join <span className="nobr">John Deere</span> at CES 2023
          </h2>
        </div>
      </div>
      <h2 className="DateTimeCallout__copy">
        <PipeJoined parts={["January 5-8, 2023", "Booth 5617 in West Hall", "Las Vegas Convention Center"]} />
      </h2>
    </PadPanel>
  );
};

export const PadPanel: React.FunctionComponent<{ children: React.ReactNode}> = (props) => {
  return <div className="PadPanel">{props.children}</div>;
};

export const TeaserPage2023: React.FunctionComponent<ITeaserPage2023Props> = (
  props
) => {
  // const teaserState = React.useContext(TeaserStateContext);
  return (
    <div className={classNames("TeaserPage2023")}>
      <TeaserHero />
      <PadPanel>
        <FancyHr />
        <PadPanel>
          <p>
            As the most influential technology event in the world, CES<sup>®</sup> 2023
            will be a global showcase for how technology can change our
            customers’ lives for the better.
          </p>
        </PadPanel>
        <p className="big-green">
          And John Deere will be at the center of it all.
        </p>
      </PadPanel>
      <KeynoteCallout />
      <FancyHr />
      <DateTimeCallout />
      <PadPanel>
        <Accolades title="Accolades" />
      </PadPanel>
      <Footer />
    </div>
  );
};
