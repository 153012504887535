import React from "react";
import "./App.scss";
import { Route, Routes } from "react-router-dom";
import { MeetTheExperts } from "./pages/experts/MeetTheExperts";
import { ModalProvider } from "./components/modal/Modal";
import { MediaResources } from "./pages/media/MediaResources";
import { usePageTracking } from "./util/use-page-tracking";
import { TeaserStateContext } from "./util/app-state";
import { TeaserPage2023 } from "./pages/home/TeaserPage2023";
import { LivePage2023 } from "./pages/home/LivePage2023";
import { FactSheet2Page } from "./pages/facts/FactSheet2Page";
import { Header } from "./components/Header";
import { PostPage2023 } from "./pages/home/PostPage2023";

function App() {
  usePageTracking();

  const teaserState = React.useContext(TeaserStateContext);

  let HomePageComponent = TeaserPage2023;

  if (teaserState.launch === "soft" || teaserState.launch === "fail") {
    HomePageComponent = LivePage2023;
  }

  if (teaserState.launch === "hard") {
    HomePageComponent = PostPage2023;
  }

  return (
    <ModalProvider>
      <Header />
      <Routes>
        <Route path="/" element={<HomePageComponent />} />
        <Route path="/experts" element={<MeetTheExperts />} />
        <Route path="/facts" element={<FactSheet2Page />} />
        <Route
          path="/media/"
          element={<MediaResources key={Math.random()} />}
        />
      </Routes>
    </ModalProvider>
  );
}

export default App;
