import classNames from "classnames";
import * as React from "react";
import { Accolades } from "../Accolades";
import "./PressReleases.scss";

interface IPressReleaseExpanderProps {
  title: string | JSX.Element;
  children: React.ReactNode;
}

export const PressReleaseExpander: React.FunctionComponent<
  IPressReleaseExpanderProps
> = (props) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div className={classNames("PressReleaseExpander", { expanded })}>
      <div className="HeaderBar" onClick={() => setExpanded(!expanded)}>
        <span>{props.title}</span>
        <div className={classNames("Expander", { expanded })} />
      </div>
      <div className={classNames("Body", { expanded })}>{props.children}</div>
    </div>
  );
};

interface IPressReleasesProps {}

export const PressReleases: React.FunctionComponent<IPressReleasesProps> = (
  props
) => {
  return (
    <div className={classNames("PressReleases")}>
      <div className="Expanders">
        <PressReleaseExpander
          title={
            <span>
              John Deere Debuts New Planting Technology & Electric Excavator
              During CES<sup>®</sup> 2023 Keynote
            </span>
          }
        >

          <h2>John Deere Debuts New Planting Technology & Electric Excavator During CES 2023 Keynote</h2>
          <p>
            MOLINE, IL (January 5, 2022) – During John Deere’s CES 2023 keynote
            address, the company revealed two new technologies, ExactShot™ and
            an electric excavator, that will help Deere’s customers be more
            productive, profitable, and sustainable.
          </p>

          <p>
            “Everything we do at John Deere is focused on real purpose and real
            impact,” said Jahmy Hindman, CTO at John Deere. “This means we’re
            developing technology that enables our customers to provide the
            food, fuel, fiber and infrastructure that our growing global
            population needs.”
          </p>

          <p>
            ExactShot allows farmers to reduce the amount of starter fertilizer
            needed during planting by more than 60%. The technology uses sensors
            and robotics to place starter fertilizer precisely onto seeds as
            they are planted in the soil, rather than applying a continuous flow
            of fertilizer to the entire row of seeds.{" "}
          </p>

          <p>
            The electric excavator, powered by a Kreisel battery, will provide
            construction workers and road builders with lower daily operating
            costs, reduced jobsite noise, enhanced machine reliability, and zero
            emissions, without sacrificing the power and performance they need
            in a machine.
          </p>

          <h4>ExactShot Details:</h4>
          <ul>
            <li>
              ExactShot will help farmers be economically and environmentally
              sustainable as they work tirelessly to grow the food, fuel and
              fiber that we all rely on. With the global population expected to
              grow from 8 billion to nearly 10 billion by 2050, farmers need to
              increase production by 60% to 70% on today’s arable land.{" "}
            </li>
            <li>
              ExactShot uses a sensor to register when each individual seed is
              in the process of going into the soil. As this occurs, a robot
              will spray only the amount of fertilizer needed, about 0.2 ML,
              directly onto the seed at the exact moment as it goes into the
              ground.{" "}
            </li>
            <li>
              Across the U.S. corn crop, ExactShot could save over 93 million
              gallons of starter fertilizer annually and prevent wasted
              fertilizer from encouraging weed growth or increasing the risk of
              running off the field into a waterway.
            </li>
          </ul>
          <h4>Electric Excavator Details:</h4>
          <ul>
            <li>
              Deere’s new electric excavator, powered by a Kreisel battery, will
              improve reliability, performance and safety in construction. Its
              use on construction sites will result in fewer moving parts, less
              noise pollution, and fewer emissions.{" "}
            </li>
            <li>
              Deere acquired a majority stake in Kreisel Electric, which created
              state-of-the-art battery technology for a wide range of mobile and
              stationary applications. Kreisel's patented immersion cooling
              architecture provides unsurpassed lifetime, enhanced safety, and
              improved performance for battery-powered equipment.{" "}
            </li>
            <li>
              Kreisel’s charging technology results in faster and lower-cost
              connections to the electrical grid.
            </li>
          </ul>
          <h4>About John Deere</h4>
          <p>
            Deere &amp; Company (
            <a href="http://www.JohnDeere.com">www.JohnDeere.com</a>) is a
            global leader in the delivery of agricultural, construction, and
            forestry equipment. We help our customers push the boundaries of
            what’s possible in ways that are more productive and sustainable to
            help life leap forward. Our technology-enabled products including
            John Deere Autonomous 8R Tractor, See & Spray™, and E-Power Backhoe
            are just some of the ways we help meet the world's increasing need
            for food, shelter, and infrastructure. Deere & Company also provides
            financial services through John Deere Financial. For more
            information on Deere & Company, visit us at{" "}
            <a href="www.deere.com/en/news/">www.deere.com/en/news/</a>.
          </p>
          <h4>Contact:</h4>
          <p>
            Franklin Peitz <br />
            Public Relations Manager, Technology & Innovation <br />
            <a href="mailto:PeitzFranklinJ@JohnDeere.com">
              PeitzFranklinJ@JohnDeere.com
            </a>
          </p>
        </PressReleaseExpander>
        <PressReleaseExpander
          title={
            <span>
              John Deere Wins Two CES<sup>®</sup> 2023 Innovation Awards in
              Robotics and Vehicle Tech &amp; Advanced Mobility Categories
            </span>
          }
        >
          <h2>
            John Deere Wins Two CES<sup>®</sup> 2023 Innovation Awards in
            Robotics and Vehicle Tech &amp; Advanced Mobility Categories
          </h2>
          <p>
            MOLINE, Illinois (November 16, 2022) – John Deere (NYSE: DE) has
            been named a CES® 2023 Innovation Awards Best of Innovation honoree
            in the Robotics category, and an honoree in the Vehicle Tech &
            Advanced Mobility category for its fully autonomous tractor. This is
            the fourth consecutive year John Deere has received an Innovation
            Award from the Consumer Technology Association (CTA). The
            announcement was made ahead of CES 2023, the world's most
            influential technology event, during which John Deere will deliver a
            keynote. CES 2023 takes place January 5-8 in Las Vegas, NV and
            digitally.
          </p>
          <p>
            John Deere’s fully autonomous tractor integrates technology such as
            cameras, artificial intelligence (AI), sensors, and ultra-fast GPU
            processors to navigate through a field without an operator in the
            cab – helping customers be more efficient, productive, and
            profitable.
          </p>
          <p>
            “Farmers never have a shortage of work to do on any given day,” said
            Jahmy Hindman, chief technology officer for John Deere. “With our
            fully autonomous tractor, farmers have the flexibility to focus on
            the most pressing tasks within their operation while the machine
            handles what they don’t have time or labor to do. We continue to
            invest in autonomy and automation to support the important work our
            customers do every day.”
          </p>
          <p>
            The CES Innovation Awards program, owned and produced by the CTA, is
            an annual competition honoring outstanding design and engineering in
            28 product categories. Judges reviewed submissions based on
            innovation, engineering and functionality, aesthetic, and design.
          </p>
          <p>
            The Robotics category of the Innovation Awards highlights
            programmable or otherwise intelligent machines capable of performing
            specific tasks or replicating human movement or interactions. The
            Vehicle Intelligence & Advanced Mobility category highlights
            automotive and other transportation products and services that
            integrate technology into the driving or riding experience, whether
            by enhancing safety, navigation, improving the passenger experience,
            or enabling self-driving functionality.
          </p>
          <h4>About John Deere</h4>
          <p>
            Deere & Company (www.JohnDeere.com) is a global leader in the
            delivery of agricultural, turf, construction, and forestry
            equipment. We help our customers push the boundaries of what’s
            possible in ways that are more productive and sustainable to help
            life leap forward. Our technology-enabled products including John
            Deere Autonomous 8R Tractor, See & Spray™, and E-Power Backhoe are
            just some of the ways we help meet the world's increasing need for
            food, shelter, and infrastructure. Deere & Company also provides
            financial services through John Deere Financial.
          </p>
          <p>
            For more information on Deere & Company, visit us at
            <a href="https://www.deere.com/en/news/">www.deere.com/en/news/</a>.
          </p>
          <h4>Contact:</h4>
          <p>
            Franklin Peitz <br />
            Public Relations Manager, Technology & Innovation <br />
            <a href="mailto:PeitzFranklinJ@JohnDeere.com">
              PeitzFranklinJ@JohnDeere.com
            </a>
          </p>
          <Accolades title="" />
        </PressReleaseExpander>
      </div>
    </div>
  );
};
