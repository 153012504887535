import * as React from "react";
import classNames from "classnames";
import { ModalContext } from "../../components/modal/Modal";
import { urlRoot } from "./MediaResources";
import { TeaserStateContext } from "../../util/app-state";

export type VideoResourcesSection = "all" | "booth" | "tractor";

export interface IVideoResourcesProps {
  section: VideoResourcesSection;
}

export const VideoResources: React.FunctionComponent<IVideoResourcesProps> = (
  props
) => {
  const [category, setCategory] = React.useState<"all" | "tractor" | "keynote" | "exactshot" | "see-and-spray">("all");
  const { handleModal } = React.useContext(ModalContext);
  const { launch } = React.useContext(TeaserStateContext);

  const vidList: {
    category: string;
    path: string;
  }[] = [
    { category: "tractor", path: "VideoFive_Front" },
    { category: "tractor", path: "VideoFour_DroneThree" },
    { category: "tractor", path: "VideoSeven" },
    { category: "exactshot", path: "ExactShot_SloMo1.1_1080" },
    { category: "exactshot", path: "ExactShot_SloMo1.2_1080" },
    { category: "see-and-spray", path: "SaSUBRoll01" },
    { category: "see-and-spray", path: "SaSUBRoll02" },
    { category: "see-and-spray", path: "SaSUBRoll03" },
    ...launch === "hard" ? [
    { category: "all", path: "protect-the-land" },
    { category: "all", path: "cutting-edge-tech" },
    { category: "all", path: "precision-ag" }] : [],
  ]
  .filter((x) => Boolean(x.category)).filter((x) => (x.category === category) || (category === "all"))
  .filter((x) => launch !== "hard" ? !["ee", "exactshot"].includes(x.category) : true);

  return (
    <div className="VideoResources">
      <div className="filters">
        <div
          onClick={() => setCategory("all")}
          className={classNames({ active: category === "all" })}
        >
          All
        </div>
        <div
          onClick={() => setCategory("tractor")}
          className={classNames({ active: category === "tractor" })}
        >
          Autonomous Tractor
        </div>
        {launch === "hard" && <div
          onClick={() => setCategory("exactshot")}
          className={classNames({ active: category === "exactshot" })}
        >
          ExactShot™
        </div>}
        {launch === "hard" && <div
          onClick={() => setCategory("keynote")}
          className={classNames({ active: category === "keynote" })}
        >
          Keynote
        </div>}
        <div
          onClick={() => setCategory("see-and-spray")}
          className={classNames({ active: category === "see-and-spray" })}
        >
          See &amp; Spray™ Ultimate
        </div>
      </div>
      {vidList.length === 0 && <div className="no-images">Coming Soon.</div>}
      {vidList.map((x) => (
        <div
          className="img-wrapper"
          key={x.path}
          onClick={() => handleModal({
            lightbox: true,
            video: `${urlRoot}/videos/${x.path.replaceAll(" ", "%20")}.mp4`,
          })}
        >
          <div
            className="img-thumb"
            style={{
              backgroundImage: `url(${urlRoot}/videos/${x.path.replaceAll(
                " ",
                "%20"
              )}.jpg)`,
            }} />
          <div className="thumb-overlay">
            <span>View</span>
          </div>
        </div>
      ))}
    </div>
  );
};
