import * as React from "react";
import classNames from "classnames";
import { ModalContext } from "../../components/modal/Modal";
import {
  IImageResourcesProps,
  JdImageResource,
  urlRoot,
} from "./MediaResources";
import { TeaserStateContext } from "../../util/app-state";

interface ImageResource {
  category: string;
  path: JdImageResource;
  label?: string;
}

export const ImageResources: React.FunctionComponent<IImageResourcesProps> = (
  props
) => {
  const { launch } = React.useContext(TeaserStateContext);

  const [category, setCategory] = React.useState<
    "all" | "tractor" | "see-and-spray" | "presenters" | "ee" | "exactshot"
  >("all");
  const { handleModal } = React.useContext(ModalContext);

  const imgList: ImageResource[] = [
    { category: "exactshot", path: "ES-1_1x.jpg"},
    { category: "exactshot", path: "ES-with-seeds-only_1x.jpg"},
    { category: "exactshot", path: "ES-with-seeds-and-plants_1x.jpg"},
    { category: "exactshot", path: "ES-only_1x.jpg"},
    { category: "exactshot", path: "ES-2_1x.jpg"},
    { category: "ee", path: "MicrosoftTeams-image_1x.jpg"},
    { category: "ee", path: "E145__00004_1x.jpg"},
    { category: "ee", path: "E145__00006_1x.jpg"},
    { category: "ee", path: "E145__00010_1x.jpg"},
    { category: "ee", path: "CHARGING_Stationary_1x.jpg"},
    { category: "see-and-spray", path: "r4f064278_rrd_1x.jpg" },
    { category: "see-and-spray", path: "r4f064314_VS_1x.jpg" },
    { category: "tractor", path: "r4f167304_rrd_1x.jpg" },
    { category: "tractor", path: "r4f167378_rrd_1x.jpg" },
    { category: "tractor", path: "r4f167445_rrd_1x.jpg" },
    { category: "tractor", path: "r4f175043_rrd_1x.jpg" },
    { category: "tractor", path: "r4f175055_rrd_1x.jpg" },
    { category: "tractor", path: "r4f175064_rrd_1x.jpg" },
  ]
    .filter((x) => Boolean(x.category))
    .filter((x) => x.category === category || category === "all")
    .filter((x) => launch !== "hard" ? !["ee", "exactshot"].includes(x.category) : true)


  function labelFor(x: { category: string; path: JdImageResource }) {
    // const path = typeof x.path === "string" ? x.path : x.path.full;

    return "View";
  }

  return (
    <div className="ImageResources">
      <div className="filters">
        <div
          onClick={() => setCategory("all")}
          className={classNames({ active: category === "all" })}
        >
          All
        </div>
        <div
          onClick={() => setCategory("tractor")}
          className={classNames({ active: category === "tractor" })}
        >
          Autonomous Tractor
        </div>
        {launch === "hard" && <div
          onClick={() => setCategory("ee")}
          className={classNames({ active: category === "ee" })}
        >
          Electric Excavator
        </div>}
        {launch === "hard" && <div
          onClick={() => setCategory("exactshot")}
          className={classNames({ active: category === "exactshot" })}
        >
          ExactShot™
        </div>}
        <div
          onClick={() => setCategory("see-and-spray")}
          className={classNames({ active: category === "see-and-spray" })}
        >
          See &amp; Spray™ Ultimate
        </div>
        {/* <div
          onClick={() => setCategory("presenters")}
          className={classNames({ active: category === "presenters" })}
        >
          Experts
        </div> */}
      </div>
      <div className={classNames("images-grid", category)}>
        {imgList.length === 0 && <div className="no-images">Coming Soon.</div>}
        {imgList.map((x) => (
          <div
            className="img-wrapper"
            key={typeof x.path === "string" ? x.path : x.path.full}
            onClick={() =>
              handleModal({
                lightbox: true,
                img: `${urlRoot}/images/${
                  typeof x.path === "string" ? x.path : x.path.full
                }`,
              })
            }
          >
            <div
              className={classNames("img-thumb", x.category)}
              style={{
                backgroundImage: `url(${urlRoot}/images/${
                  typeof x.path === "string" ? x.path : x.path.thumbnail
                })`,
              }}
            />
            <div className="thumb-overlay">
              <span>{labelFor(x)}</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
