import * as React from "react";

import "./PostPage2023.scss";
import classNames from "classnames";
import Footer from "../../components/Footer";
import { Accolades } from "../media/Accolades";
import { ReactComponent as Logo } from "./img/real_logo.svg";
import { HeroLiveStream } from "./HeroLiveStream";
import { AnchorOutlineButton } from "../../components/OutlineButton";
import { ProgramSchedule } from "./ProgramSchedule";
import { EECallout } from "./EECallout";
import { Constants } from "../../util/constants";

interface PostPage2023Props {}

export const TeaserHero: React.FunctionComponent = () => {
  return (
    <div className="TeaserHero">
      <video className="TeaserHero__video" autoPlay muted loop playsInline>
        <source
          src="https://jd-ces-2023.s3.amazonaws.com/media-resources/Deere-CES_2023_Home-Video_1920x1080.mp4"
          type="video/mp4"
        />
      </video>
      <div className="TeaserHero__content">
        <Logo className="RealLogo" />
      </div>
    </div>
  );
};

export const FancyHr: React.FunctionComponent = () => {
  return (
    <PadPanel>
      <div className="FancyHr"></div>
    </PadPanel>
  );
};

export const PipeJoined: React.FunctionComponent<{ parts: string[] }> = (
  props
) => {
  return (
    <span className="PipeJoined">
      {props.parts.map((part, i) => (
        <span key={i}>
          {part}
          {i < props.parts.length - 1 ? (
            <span className="PipeJoined__pipe"></span>
          ) : null}
        </span>
      ))}
    </span>
  );
};

export const KeynoteCallout: React.FunctionComponent = () => {
  return (
    <div className="KeynoteCallout">
      <div className="KeynoteCallout__content">
        <h2 className="KeynoteCallout__title">CES 2023 KEYNOTE ADDRESS</h2>
        <h3 className="KeynoteCallout__subtitle">
          <PipeJoined parts={["January 5, 2023", "11am CST (9am PST)"]} />
        </h3>
        <PadPanel>
          <p>
            Join Chairman and CEO John May as he provides a unique look at how
            John Deere technology is helping customers do more with less — and
            feed our growing world.
          </p>
          <AnchorOutlineButton
            className="yellow"
            to={Constants.stream_link}
            text="Watch Now"
          />
        </PadPanel>
      </div>
    </div>
  );
};

export const DateTimeCallout: React.FunctionComponent = () => {
  return (
    <>
      <div className="DateTimeCallout">
        <div className="DateTimeCallout__content">
          <h2 className="DateTimeCallout__title">
            Visit the <span className="nobr">John Deere</span> CES Booth
          </h2>
          <AnchorOutlineButton
            className="yellow"
            to="https://jd-ces-2023.s3.amazonaws.com/media-resources/Booth-Locations_CES-2023.pdf"
            text="View Booth Location"
          />
        </div>
      </div>
      <h2 className="DateTimeCallout__copy">
        <PipeJoined
          parts={[
            "January 5-8, 2023",
            "Booth 5617 in West Hall",
            "Las Vegas Convention Center",
          ]}
        />
      </h2>
    </>
  );
};

export const PadPanel: React.FunctionComponent<{
  children: React.ReactNode;
}> = (props) => {
  return <div className="PadPanel">{props.children}</div>;
};

export const PostPage2023: React.FunctionComponent<PostPage2023Props> = (
  props
) => {
  // const teaserState = React.useContext(TeaserStateContext);
  return (
    <div className={classNames("PostPage2023")}>
      <TeaserHero />
      <PadPanel>
        <FancyHr />
        <PadPanel>
          <div className="page-intro">John Deere at CES<sup>®</sup> 2023</div>
        </PadPanel>
        <HeroLiveStream />
      </PadPanel>
      <DateTimeCallout />
      <FancyHr />
      <ProgramSchedule />
      <EECallout />
      <PadPanel>
        <Accolades title="Accolades" />
      </PadPanel>
      <Footer />
    </div>
  );
};
