import * as React from "react";
// import AC1 from "./img/ces2020-innovation-award_1_@2X.png";
// import AC2 from "./img/ces2020-innovation-award_2_@2X.png";
import AC3 from "./img/CES-award-boi-2023.png";
import AC4 from "./img/CES-award-honoree-2023.png";
import "./Accolades.scss";

interface IAccoladesProps {
  title: string;
}

export const Accolades: React.FunctionComponent<IAccoladesProps> = (props) => {
  const cesUrl = "https://www.ces.tech/Innovation-Awards/Honorees/2023/Best-Of/J/John-Deere-Autonomous-Tractor.aspx";

  return (
    <div className="Accolades">
      {props.title && <h2 className="green">{props.title}</h2>}
      <div className="badges">
        <div>
          <a
            href={cesUrl}
            target="_blank"
            rel="noreferrer"
          >
            <img src={AC3} alt="" />
          </a>
          <h4>
            CES INNOVATION AWARDS 2023 BEST OF INNOVATION <br/> <span style={{fontSize: "0.8em", fontWeight: "normal"}}>ROBOTICS</span>
          </h4>
        </div>
        <div>
          <a
            href={cesUrl}
            target="_blank"
            rel="noreferrer"
          >
            <img src={AC4} alt="" />
          </a>
          <h4>
            CES INNOVATION AWARDS 2023 HONOREE <br/> <span style={{fontSize: "0.8em", fontWeight: "normal"}}>VEHICLE TECH</span>
          </h4>
        </div>
      </div>
    </div>
  );
};
