import classNames from "classnames";
import * as React from "react";
import { VideoOverlay } from "../../components/VideoOverlay";
import "./ProgramSchedule.scss";

interface IProgramExpanderProps {
  title: string;
  presenter: string;
  children: React.ReactNode;
}

export const ProgramExpander: React.FunctionComponent<IProgramExpanderProps> = (
  props
) => {
  const [expanded, setExpanded] = React.useState(false);
  return (
    <div className={classNames("ProgramExpander", { expanded })}>
      <div className="HeaderBar" onClick={() => setExpanded(!expanded)}>
        <span className="Presenter">{props.title}</span>
        <span className="Splitter Pipe"> | </span>
        <span className="Date">{props.presenter}</span>
        <div className={classNames("Expander", { expanded })} />
      </div>
      <div className={classNames("Body", { expanded })}>{props.children}</div>
    </div>
  );
};

interface IProgramScheduleProps {}

export const ProgramSchedule: React.FunctionComponent<IProgramScheduleProps> = (
  props
) => {
  const [video, setVideo] = React.useState("");

  return (
    <div
      className={classNames("ProgramSchedule", {
        showingVideo: Boolean(video),
      })}
    >
      <h2>
        CES 2023 Program Schedule
      </h2>
      <div className="Expanders">

        <ProgramExpander
          title="Trends in Artificial Intelligence Today"
          presenter="Cory Reed"
        >
          <div>
            <strong>Date:</strong> Thursday, January 5, 2023
          </div>
          <div>
            <strong>Time:</strong> 9:00–9:40am CST (11:00–11:40am PST)
          </div>
          <div>
            <strong>Session Overview:</strong> Artificial intelligence (AI) has
            grown rapidly in the last few years. The use of the latest AI
            technologies has been helpful across multiple industries. Join our
            experts as they walk through the latest trends in AI.
          </div>
          <div>
            <strong>Speaker:</strong> Jason Carmel
          </div>

          <div>
            <strong>Location:</strong> LVCC, North Level 2, N251
          </div>
          <div>
            <strong>Moderator:</strong> Jason Carmel, Global Lead Creative Data,
            Wunderman Thompson Panelists: Rana el Kaliouby, Deputy CEO, Smart
            Eye; Tim Porter, Chief Technology Officer, Mod Tech Labs; Cory Reed,
            President, Worldwide Ag & Turf Division, John Deere
          </div>
        </ProgramExpander>

        <ProgramExpander
          title="A “New” Normal is Here Along with a New Workforce"
          presenter="Felicia Pryor"
        >
          <div>
            <strong>Date:</strong> Thursday, January 5, 2023
          </div>
          <div>
            <strong>Time:</strong> 11:00–11:40am CST (1:00–1:40pm PST)
          </div>
          <div>
            <strong>Session Overview:</strong> Millions of new jobs were created
            in the past year while the unemployment rate hovers at record lows.
            It’s a job-seeker’s market, and workers are demanding more from their
            employers. What are the secrets to success in attracting and retaining
            a productive and engaged workforce?
          </div>
          <div>
            <strong>Moderator:</strong> Jeanniey Walden, Chief Innovation &amp; Marketing Officer, DailyPay
          </div>
          <div>
            <strong>Panelists:</strong> Zoë Harte, Chief People Officer, UpWork; Beth Hayden, Chief People Officer, SpotHero; Nichole Jordan, Central Region Managing Partner, Grant Thornton LLP; Felicia Pryor, Senior Vice President and Chief People Officer, John Deere
          </div>
        </ProgramExpander>
      </div>
      {video && (
        <VideoOverlay
          src={video}
          show={Boolean(video)}
          onOverlayClicked={() => setVideo("")}
        />
      )}
    </div>
  );
};
