import { useContext } from "react";
import { TeaserStateContext } from "../util/app-state";

/**
 * simple drop to switch between teaser, soft, and hard launch states
 */
export const StateSwitch = () => {
  const { env, launch, setLaunch } = useContext(TeaserStateContext);
  if (env === "prod") return null;

  return (
    <div className="StateSwitch" style={{
      position: "fixed",
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
      zIndex: 1000,
      padding: "1rem"
    }}>
      <select
        value={launch}
        onChange={(e) => setLaunch(e.target.value as "tease" | "soft" | "hard")}
      >
        <option value="tease">"Teaser"</option>
        <option value="soft">During Keynote</option>
        <option value="fail">Stream Failure</option>
        <option value="hard">Post-Keynote</option>
      </select>
    </div>
  );
};
