import * as React from "react";

import "./LivePage2023.scss";
import classNames from "classnames";
import Footer from "../../components/Footer";
import { Accolades } from "../media/Accolades";
import { ReactComponent as Logo } from "./img/real_logo.svg";
import { HeroLiveStream } from "./HeroLiveStream";
import { AnchorOutlineButton } from "../../components/OutlineButton";
import { ProgramSchedule } from "./ProgramSchedule";
import { TeaserStateContext } from "../../util/app-state";

interface LivePage2023Props {}

export const TeaserHero: React.FunctionComponent = () => {
  return (
    <div className="TeaserHero">
      <div className="TeaserHero__content">
        <Logo className="RealLogo" />
      </div>
    </div>
  );
};

export const FancyHr: React.FunctionComponent = () => {
  return (
    <PadPanel>
      <div className="FancyHr"></div>
    </PadPanel>
  );
};

export const PipeJoined: React.FunctionComponent<{ parts: string[] }> = (
  props
) => {
  return (
    <span className="PipeJoined">
      {props.parts.map((part, i) => (
        <span key={i}>
          {part}
          {i < props.parts.length - 1 ? (
            <span className="PipeJoined__pipe"></span>
          ) : null}
        </span>
      ))}
    </span>
  );
};

export const DateTimeCallout: React.FunctionComponent = () => {
  return (
    <>
      <div className="DateTimeCallout">
        <div className="DateTimeCallout__content">
          <h2 className="DateTimeCallout__title">
            Visit the <span className="nobr">John Deere</span> CES Booth
          </h2>
          <AnchorOutlineButton className="yellow" to="https://jd-ces-2023.s3.amazonaws.com/media-resources/Booth-Locations_CES-2023.pdf" text="View Booth Location"/>
        </div>
      </div>
      <h2 className="DateTimeCallout__copy">
        <PipeJoined parts={["January 5-8, 2023", "Booth 5617 in West Hall", "Las Vegas Convention Center"]} />
      </h2>
    </>
  );
};

export const PadPanel: React.FunctionComponent<{ children: React.ReactNode }> = (props) => {
  return <div className="PadPanel">{props.children}</div>;
};

export const LivePage2023: React.FunctionComponent<LivePage2023Props> = (
  props
) => {
  const { launch } = React.useContext(TeaserStateContext);
  return (
    <div className={classNames("LivePage2023")}>
      <HeroLiveStream />
      <PadPanel>
        <FancyHr />
        <PadPanel>
          <div className="page-intro">John Deere at CES<sup>{launch === "fail" ? "®" : ""}</sup> 2023</div>
        </PadPanel>
      </PadPanel>
      <DateTimeCallout />
      <FancyHr />
      <ProgramSchedule />
      <PadPanel>
        <Accolades title="Accolades" />
      </PadPanel>
      <Footer />
    </div>
  );
};
