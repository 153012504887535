import * as React from "react";
import { AnchorOutlineButton } from "../../components/OutlineButton";
import { PadPanel } from "./PostPage2023";


export const EECallout: React.FunctionComponent = () => {
  return (
    <div className="KeynoteCallout EECallout">
      <div className="KeynoteCallout__content">
        <h2 className="KeynoteCallout__title">Delivering on our promise</h2>
        <PadPanel>
          <p>
            With a stated goal of more than 20 electric and hybrid-electric
            construction equipment models by 2026, we’re well down the road on
            our electric journey. The world premiere of our first electric
            excavator shows our commitment to this promise with the most heavily
            used machine form on the jobsite. And in addition to utilizing the
            latest in Kreisel Electric’s battery portfolio, the 145 X-Tier
            boasts the latest in construction jobsite technologies and
            operator-driven comfort.
          </p>
          <AnchorOutlineButton
            className="yellow"
            to="https://www.deere.com/en/our-company/electrification/construction/"
            text="Learn More" />
        </PadPanel>
      </div>
    </div>
  );
};
