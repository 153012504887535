import * as React from "react";
import { IExpertSlideProps } from "./MeetTheExperts";

export const EXPERTS_2022: IExpertSlideProps[] = [
  {
    name: "Jahmy Hindman",
    src: "JahmyHindman",
    title: "Chief Technology Officer",
    company: "John Deere",
    bio: "Jahmy Hindman, Ph.D., is the chief technology officer at John Deere, a position he has held since July 2020. He is responsible for building Deere’s “tech stack,” the company’s intuitive end-to-end equipment solution made up of hardware and devices, embedded software, connectivity, data platforms, and applications. He also leads the company’s Intelligent Solutions Group (ISG) and is responsible for the global network of technology/innovation centers and the shared engineering function. Jahmy has more than 20 years of advanced technology, artificial intelligence, product engineering, and manufacturing experience. He earned his bachelor’s degree in mechanical engineering from Iowa State University as well as master’s and doctoral degrees in mechanical engineering from the University of Saskatchewan.",
  },
  {
    name: "Deanna Kovar",
    src: "DeannaKovar",
    title: "Vice President of Production and Precision Ag Production Systems",
    company: "John Deere",
    bio: "Deanna Kovar is the vice president of Production and Precision Ag Production Systems at John Deere. With expertise in agronomy, economics, products, and technology, she leads a global team focused on identifying opportunities to unlock sustainable value for global corn, soy, small grains, cotton, and sugar customers. During more than 20 years with Deere, she has held many roles in sales, marketing, and customer support, and is very knowledgeable about bringing Precision Ag technology to the market. Deanna earned a bachelor’s degree in agricultural business management from the University of Wisconsin at Madison and a master’s in business administration from Loyola University in Chicago.",
  },
  {
    name: "Jason Daly",
    src: "JasonDaly",
    title: "Vice President, Earthmoving Sales & Marketing",
    group: "John Deere Worldwide Construction & Forestry",
    bio: "Jason Daly is the vice president of earthmoving sales & marketing for the John Deere Construction & Forestry Division, where he oversees the success of the company’s earthmoving performance on a global basis. Throughout his 26 years at Deere, he has led production systems, technology solutions, tactical marketing, marketing communications, data analytics, market research, and training. Under his leadership, Jason’s team delivers investments in a robust portfolio of technology-forward solutions to answer the fundamental need for smarter, safer, and more sustainable construction. These portfolio investments include construction automation, jobsite safety, electrification, and autonomy. Jason earned a bachelor’s degree in agriculture economics from the University of Illinois and a master’s in business administration from the University of Maryland. He also has executive leadership certificates from the Kellogg School of Business and the Tuck School of Business."
  },
  {
    name: "Jorge Heraud",
    src: "JorgeHeraud",
    title: "Vice President of Automation and Autonomy",
    group: "John Deere Intelligent Solutions Group",
    bio: "Jorge Herald is the vice president of automation and autonomy within the Intelligent Solutions Group (ISG) at John Deere, where he leads the development efforts for products around automation and autonomy to make customers more productive, profitable, and sustainable. Prior to his career with John Deere, Jorge was the CEO and co-founder of Blue River Technology, which was acquired by Deere & Company in September 2017 and is now run as an independent subsidiary. He holds a bachelor’s in electrical engineering from Universidad Catolica del Peru and a master’s in electrical engineering and engineering management from Stanford University.",
  },
  {
    name: "Jenny Ose",
    src: "JennyOse",
    title: "Director of Marketing and Shared Services",
    group: "John Deere Ag & Turf Division",
    bio: "Jenny Ose is the director of marketing and shared services for the John Deere Ag & Turf Division. Together with her team, she focuses on transforming Deere’s business by building and delivering world-class marketing capabilities and enhanced go-to-market support for our dealers in the U.S. and Canada. Jenny has worked for John Deere for 25 years in a variety of roles and locations. She has experience in all Ag & Turf segments including turf, small ag, large ag, and multiple product and factory marketing positions throughout the U.S.; Zweibrücken, Germany; and South America. In addition, Jenny has worked in field sales and territory roles throughout the U.S. and Canada. She earned a bachelor’s degree from Augsburg University and a master’s in business administration from Kellogg School of Management at Northwestern University.",
  },

  {
    name: "Julian Sanchez",
    src: "JulianSanchez",
    title: "Director of Emerging Technologies",
    group: "Intelligent Solutions Group John Deere",
    bio: "Julian Sanchez, Ph.D., is a John Deere Fellow and the director of emerging technologies in the Intelligent Solutions Group (ISG) at John Deere. His team focuses on researching, developing, and supporting the deployment of Deere’s next-generation technologies to improve customer profitability and sustainability. As a seasoned technologist, he has made significant contributions at John Deere including the establishment of UX capabilities and the early deployment of digital solutions in agriculture. Julian holds over 20 patents and has published dozens of peer-reviewed studies in the areas of aviation, agriculture, and medical devices. He earned his bachelor’s in industrial engineering and a master’s in industrial and systems engineering from Florida International University and his Ph.D. in human factors from Georgia Institute of Technology.",
  },
  {
    name: "Nancy Post",
    src: "NancyPost",
    title: "Vice President of Embedded Software and Solutions",
    company: "John Deere",
    bio: "Nancy Post is the vice president of embedded software and solutions at John Deere. She is responsible for leading the development and commercialization of core Precision Agriculture Technology solutions, including GNSS receivers, correction signals, displays, universal guidance systems, and telematics devices. Nancy is also responsible for the global electronics group, a team that develops software and hardware systems, architecture, infrastructure, and operating systems for the enterprise. In addition to her 25-plus years at Deere, she brings expertise and experience from positions at other manufacturing companies, including Rockwell International and Compressor Controls Corporation. Nancy earned a bachelor’s degree in computer science from Mount Mercy University and a master’s in business administration from the University of Phoenix."
  },
  {
    name: "Lane Arthur",
    src: "LaneArthur",
    title: "Vice President of Data, Applications, and Analytics",
    group: "John Deere Intelligent Solutions Group",
    bio: "Lane Arthur, Ph.D., is the vice president of data, applications, and analytics for the Intelligent Solutions Group (ISG) at John Deere. He oversees the design, delivery, and support of information-enabled solutions that allow farmers, ag solutions providers, and construction owners to leverage data and develop insights to make operations more profitable. Lane is also focused on the creation and execution of the John Deere Operations Center™, a set of online and mobile tools that enable access to farm information anytime, anywhere. He is responsible for the company’s technology businesses in Europe and South America. Before Deere, Lane held leadership roles at DuPont Pioneer®, including vice president and chief information officer, in which he oversaw the deployment of global systems that applied analytical methods and modeling with large data sets to solve business problems. He earned a bachelor’s degree in biochemistry from the University of Georgia and a Ph.D. in genetics and development from Columbia University Vagelos College of Physicians and Surgeons.",
  },


  {
    name: "Willy Pell",
    src: "WillyPell",
    title: "Vice President of Autonomy and New Ventures",
    company: "Blue River Technology",
    bio: "Willy Pell is the vice president of autonomy and new ventures at Blue River Technology. He is responsible for engineering and product development for the computer vision parts of autonomy and for new ventures at Blue River Technology. Willy started his career at Boeing, essentially creating a Google Earth for defense applications. He later moved to a Silicon Valley startup called 510 Systems and rolled it into Project Chauffeur that eventually became Waymo. Willy became an early employee at Blue River Technology, which applied computer vision and robotics to agriculture. He carries an impressive software background and is passionate about building systems that create a positive impact on the world. Willy holds a bachelor’s degree in computer sciences from University of Colorado Boulder.",
  },
  {
    name: "Aubrey Donnellan",
    src: "AubreyDonnellan",
    title: "COO and Co-Founder",
    company: "Bear Flag Robotics",
    bio: "Aubrey Donnellan is the COO and co-founder of Bear Flag Robotics, which builds and develops autonomous technology for farm tractors. Bear Flag’s mission is to reduce the cost of growing food and increase global food production through machine automation. Bear Flag was acquired by Deere & Company in August 2021 and is now run as an independent subsidiary. Aubrey is an entrepreneur and previous founder in the Food Tech space, and she is passionate about the intersection of technology and environmental sustainability. Aubrey earned a bachelor’s degree in mechanical engineering from Carnegie Mellon University and a master’s in business administration from The University of Chicago Booth School of Business.",
  },

  {
    name: "Joel Dawson",
    src: "JoelDawson",
    title: "Manager of Production and Precision Ag Marketing",
    company: "John Deere",
    bio: "Joel Dawson is the manager of Production and Precision Ag Marketing at John Deere, where he leads a team that executes all go-to-market activities for production and Precision Ag equipment and technology for North America. Through his 19-year career at John Deere, Joel has worked to shape the company’s strategy for technology integration by creating and executing channel strategies that deliver on customers’ dynamic needs surrounding technology. He holds a bachelor’s in marketing and supply management from Western Illinois University and a master’s in business administration from the University of Phoenix.",
  },
  {
    name: "Crystal Wells",
    src: "CrystalWells",
    title: "Tech Stack Architecture Manager",
    company: "John Deere Intelligent Solutions Group",
    bio: "Crystal Wells is the manager for tech stack architecture in the Intelligent Solutions Group (ISG) at John Deere. Her team focuses on various aspects of the Deere tech stack, including data, digital, electrical systems, embedded precision tech, and vehicle technologies. Crystal leads her team in developing production systems from “end to end,” meaning from the cloud to the machine and back. She has held various roles in the engineering and product-development spaces over her 16-year career with John Deere. In addition to her work at Deere, Crystal is a passionate advocate for women in engineering. Each year, she leads a Women in Product Engineering cohort that develops a business acumen for future women engineering leaders. Crystal earned a bachelor’s degree in electrical engineering from the University of Wisconsin–Platteville and a master’s degree in engineering management from Iowa State University. She also earned a system design and management certificate from the Massachusetts Institute of Technology."
  },
  {
    name: "Lee Johnson",
    src: "LeeJohnson",
    title: "Engineering Manager for Technology Development",
    company: "John Deere",
    bio: "Lee Johnson is an engineering manager of technology development at John Deere. He and his team lead the advanced engineering and machine architecture projects for planting, small grains seeding, spraying, and nutrient application. Together, they develop new solutions and design initial concepts for machines. In his 28 years with John Deere, Lee has been awarded more than a dozen patents along with multiple Deere Innovation, Collaboration, and Community Service awards. He earned a bachelor’s degree in mechanical engineering from North Dakota State University. Lee also earned a systems design and management graduate certification from the Massachusetts Institute of Technology."
  },
  {
    name: "Zimin Vilar",
    src: "ZiminVilar",
    title: "Battery Electric Vehicle Platform Manager",
    company: "John Deere Worldwide Contstruction & Forestry Division",
    bio: "Zimin Vilar is the battery electric vehicle (BEV) platform manager for the John Deere Construction & Forestry Division. She leads the BEV design, marketing and manufacturing team, which focuses on delivering BEV machines to Deere customers and the rest of the market. Zimin was the design lead for the John Deere E-Drive programs, which have greatly benefited customers for almost a decade. She is proud to be part of the team that first brought electrification to market in the industry. Zimin earned a bachelor’s degree in electrical and electronics engineering from Wuhan University and a master’s degree in electrical and electronics engineering from the University of South Carolina."
  },
  {
    name: "Tiffany Ingersoll",
    src: "TiffanyIngersoll",
    title: "Senior Product Manager in Application Automation",
    company: "John Deere",
    bio: "Tiffany Ingersoll is a senior product manager in application automation at John Deere. She helps create world-class products by uniting technology and vehicle design to generate value for Deere and its customers. During 22-plus years with John Deere, Tiffany has worked on various projects that solve real customer challenges. One of her favorite experiences at Deere includes her participation in developing See & Spray™ Select and Ultimate, in which she collaborated with high-talent individuals across multiple organizations to deliver the solutions to customers. Tiffany earned a bachelor’s degree in industrial engineering from Iowa State University and a master’s in business administration from Drake University.",
  },
  {
    name: "Matthew Potter",
    src: "MathewPotter",
    title: "Senior Director of Engineering for See & Spray™",
    company: "John Deere",
    bio: "Matthew Potter is the senior director of engineering for See & Spray™, where he developed the engineering organization at Blue River Technology, which John Deere acquired in September 2017. In partnership with the Intelligent Solutions Group (ISG) at John Deere and the sprayer team, Matthew and his team developed and delivered the computer vision and data systems for See & Spray™ Ultimate. Throughout his 10-year career with John Deere, he has led various software teams that deliver products in the GPS-enabled guidance, electrification, hybrid drivetrain, machine learning, and computer vision spaces. Matthew earned a bachelor’s degree in mechanical engineering from the University of Nebraska, a master’s degree in mechanical engineering from the University of Minnesota, and a master’s degree in engineering management from the University of Wisconsin–Madison."
  },
  {
    name: "Al Savage",
    src: "AlSavage",
    title: "Manager of StarFire™ Network",
    group: "John Deere Intelligent Solutions Group",
    bio: "Al Savage is the manager of the StarFire™ Network within the Intelligent Solutions Group (ISG) at John Deere. Together with his team, they provide 99.999-percent uptime of the GPS signal to Deere customers and their operations while continuing to innovate the network for future enhancements. Throughout his 10-year career with John Deere he has held many roles, but most notably was part of the team that launched two new StarFire signals (SF3 and SF4) and built two new data centers. Al has earned a bachelor’s degree from ITT in electronics engineering and a master’s in business administration from University of Phoenix.",
  },
  {
    name: "Kevin Seidl",
    src: "KevinSeidl",
    title: "Group Product Manager for John Deere Operations Center™",
    company: "John Deere",
    bio: "Kevin Seidl is a group product manager for the John Deere Operations Center™ (JDOC) at John Deere. He leads a group of product managers responsible for key features within the JDOC, with a specific focus on live remote monitoring and equipment & agronomic analysis capabilities. As a software engineer, Kevin has built some of the foundational features of the JDOC program. Throughout his 13-plus years at John Deere, he has had various product management roles of increasing responsibility, where he has focused on the JDOC’s monitoring and analysis capabilities. Kevin earned a bachelor’s degree in computer science from Bradley University and a master’s in business administration from the University of Iowa.",
  },

  {
    name: "Eric Crawford",
    src: "EricCrawford",
    title: "Engineering Lead of Innovation Enablement",
    company: "John Deere",
    bio: "Eric Crawford is the engineering lead of innovation enablement at John Deere. He and his team help architect the company’s Technology Development Processes to create consistency across production teams. Eric has over eight years of experience leading software development teams, currently leading a team of developers who focus on the digital and embedded space. He also worked as a mobile app developer. In this role, Eric developed and released John Deere’s first mobile apps in-house. Some of these programs include GoPlant, GoMow, GoHaul, GrainTruckPlus, and the App Center. Eric earned an associate’s degree in science from John A. Logan College and a bachelor’s degree in computer science from the University of Illinois Springfield."
  },
  /**
   * Madelyn Koester Senior Product Manager and Soil Health Lead, John Deere
   */
  {
    name: "Madelyn Koester",
    src: "MadelynKoester",
    title: "Senior Product Manager and Soil Health Lead",
    company: "John Deere",
    bio: "Madelyn Koester is a senior product manager and soil health lead at John Deere. She leads sustainability pilot programs, John Deere Operations Center™ development, and digital soil health efforts that help farmers become more sustainable and profitable. Prior to joining John Deere in 2021, Madelyn worked in product management roles with Syngenta® and AGCO, which allowed her to gain a broad perspective on agricultural software and business models. She earned a bachelor’s degree in agricultural & consumer economics from the University of Illinois at Urbana–Champaign and is currently working toward a master’s in business administration from Northwestern University."
  },
  {
    name: "Chung Hsieh",
    src: "ChungHsieh",
    title: "Senior Infrastructure Engineer",
    company: "John Deere",
    bio: "Chung Hsieh is a senior infrastructure engineer at John Deere who has worked within the StarFire™ network in various capacities throughout his 11 years with the company. He architected and drove the implementation of the current iteration of the StarFire network. Currently, Chung works with all aspects of the StarFire network, such as its IT server and networking infrastructure, and its satellite communication and infrastructure. Prior to his career with John Deere, he owned and operated an engineering services company that specialized in high-speed data capture and signal processing of radar systems. Chung earned bachelor’s and master’s degrees in electrical engineering from the University of California, Los Angeles."
  },
  {
    name: "Preston Moore",
    src: "PrestonMoore",
    title: "Electrification Solution Planner and Product Manager",
    company: "John Deere",
    bio: "Preston Moore is an electrification solution planner and product manager at John Deere, where he works with over 20 vehicle teams around the Deere enterprise. He also works with Kreisel Battery and its customers to determine what batteries John Deere should produce and use in its vehicles. Preston’s 15 years of experience at the company has offered him valuable knowledge in diesel engines and powertrain applications and integration. He has worked on over 50 vehicle programs during his time with John Deere. Preston earned a master’s degree in technology management from the University of Northern Iowa, where he is also working toward a master’s in business administration."
  },
  {
    //Kathleen Sprouse Senior Product Marketing Manager for See & Spray™, Blue River Technology
    name: "Kathleen Sprouse",
    src: "KathleenSprouse",
    title: "Senior Product Marketing Manager for See & Spray™",
    company: "Blue River Technology",
    bio: "Kathleen Sprouse is the senior product marketing manager for See & Spray™ at Blue River Technology. In her role, she focuses on identifying and sizing customer problems to understand the value of providing solutions. Kathleen collaborates with Blue River Technology and John Deere colleagues, enabling an exciting view of how to develop new technologies and build high-quality products that customers can depend on. As part of the fourth generation of a family row-crop and cow-calf operation in Missouri, Kathleen’s passion for agriculture has led her to find new farming methods that improve agriculture operations in local communities and around the world. She is passionate about Blue River Technology’s mission — to use technology to benefit the planet, starting with agriculture. Kathleen earned a bachelor’s degree in agricultural journalism from the University of Missouri–Columbia and a master’s degree in agriculture, food, and resource economics from Michigan State University."
  },
  {
    name: "Jon Ebert",
    src: "JonEbert",
    title: "Manager, North American Public & Industry Relations",
    group: "John Deere Ag & Turf Division",
    bio: (
      <>
        <div>Mobile: (913) 302-0592</div>
        <div><a href="mailto:EbertJonathanP@JohnDeere.com">EbertJonathanP@JohnDeere.com</a></div>
      </>
    ),
  },
  {
    name: "Franklin Peitz",
    src: "FranklinPeitz",
    title: "Technology and Innovation Public Relations Manager",
    group: "John Deere",
    bio: (
      <>
        <div>Mobile: (515) 776-7329</div>
        <div><a href="mailto:PeitzFranklinJ@JohnDeere.com">PeitzFranklinJ@JohnDeere.com</a></div>
      </>
    ),
  },
];
