/* eslint-disable jsx-a11y/anchor-has-content */
import * as React from "react";
import { TeaserStateContext } from "../../util/app-state";
import { Constants } from "../../util/constants";
import "./HeroLiveStream.scss";

interface IHeroLiveStreamProps {}

function LiveStreamFail() {
  return (
    <div className="HeroLiveStream">
      <div className="HeroLiveStream__content">
        <h2>Live Stream Currently Unavailable.</h2>
        <h3>A recording of the full keynote will be posted shortly.</h3>
      </div>
    </div>
  );
}

function LiveStreamSuccess() {
  const { launch } = React.useContext(TeaserStateContext);

  return (
    <div className="HeroLiveStream">
      <div className="HeroLiveStream__content">
        <h2>
          Watch the live CES 2023<sup>{launch === "soft" ? "®" : ""}</sup> Keynote
        </h2>
        <a href={Constants.stream_link} target="_blank" rel="noreferrer"></a>
      </div>
    </div>
  );
}

export const HeroLiveStream: React.FunctionComponent<IHeroLiveStreamProps> = (
  props
) => {
  const { launch } = React.useContext(TeaserStateContext);
  if (launch === "fail") {
    return <LiveStreamFail></LiveStreamFail>;
  }
  return <LiveStreamSuccess></LiveStreamSuccess>;
};
